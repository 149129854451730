<template>
    <div class="d-flex flex-column flex-center flex-column-fluid p-10">
        <!--begin::Illustration-->
        <!--begin::Message-->
        <h1 class="fw-bold mb-10" style="color: #a3a3c7">Vous êtes deconnecté</h1>
        <!--end::Message-->

        <!--begin::Link-->
        <p class="mb-15">Veuillez fermer votre navigateur.</p>
        <!--end::Link-->

        
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
    name: "error-404",
    components: {},
    setup() {
        const store = useStore();
        const router = useRouter();

        onMounted(() => {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
        });

        onUnmounted(() => {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
        });

        
    },
    
});
</script>
